import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Nathan CKN</h1>
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <Routes>
            <Route path="/" element={<h1>Home</h1>} />
            <Route path="contact" element={<Contact />} />
          </Routes>
          <Link to="/">Home</Link>
          <Link to="contact">Contact link</Link>
          <a href="contact">Contact href</a>
          <a href="html/numberSpeech">HTML</a>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </Router>
    </div>
  );
}

export default App;
